import React from 'react'
import bg3 from '../img/bg3.png'
import Asset17 from '../img/Asset17.png'
import Asset18 from '../img/Asset18.png'

export default function Majors({objectProp}) {
  return (
    // các ngành đào tạo
    <div className={ !objectProp.isMobile ?
        "w-[100%] h-[600px] m-auto relative"
        :"w-[100%] h-[670px] relative mb-[180px]"
    } id="majors">
        <img src={bg3} className={ !objectProp.isMobile ?
            "w-[100%] h-[100%]" 
            : "w-[100%] h-[770px]"  
        }/>
        <p className={ !objectProp.isMobile && !objectProp.isTablet ?
            "absolute top-[30px] left-[50%] translate-x-[-50%] text-[40px] font-bold text-[#0046CD]"
            : "w-full absolute top-[30px] text-center text-[25px] font-bold text-[#0046CD]"
        }>CÁC NGÀNH ĐÀO TẠO </p>

        <div className={ objectProp.isMobile ?
            "w-full h-[670px] absolute top-[61%] translate-y-[-50%] flex flex-wrap justify-around px-[40px]"
            : objectProp.isTablet ? "w-full h-[400px] absolute top-[55%] translate-y-[-50%] flex flex-wrap justify-around"
            : "max-w-[2000px] w-full h-[400px] absolute top-[55%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex flex-wrap justify-around px-[40px]"
        }>
            <div className={ objectProp.isMobile ?
                "w-[100%] h-auto relative"
                : objectProp.isTablet ? "w-[50%] h-[100%] relative"
                : objectProp.isTabletBig ? "w-[50%] h-[100%] relative"
                : "w-[40%] h-[100%] relative"
            }>
                <img src={Asset17} className={ objectProp.isMobile ?
                    "w-[100%] h-auto bg-cover absolute bottom-0"
                    : objectProp.isTablet ? "w-[100%] h-full"
                    : "w-[100%] h-[450px]"
                }/>
                <div className={ objectProp.isMobile ?
                    "absolute bottom-[20px] w-[60%] text-white ml-[65px]"
                    : objectProp.isTablet ? "absolute bottom-[20px] w-[60%] text-white ml-[65px]"
                    : "absolute bottom-[20px] w-[60%] text-white ml-[65px]"
                }>
                    <p className={ objectProp.isMobile ?
                        "text-[20px] font-bold ml-[-40px]"
                        : objectProp.isTablet ? "text-[30px] font-bold"
                        : "text-[30px] font-bold"
                    }>NGÔN NGỮ ANH</p>
                    <p className={
                        objectProp.isMobile ?
                        "w-[130%] h-auto ml-[-40px] mb-[10px] text-[13px]"
                        : objectProp.isTablet ? ""
                        : ""
                    }>Cơ hội nghề nghiệp rộng mở, với chuyên ngành đào tạo đáp ứng thị trường hội nhập đa quốc gia</p>
                </div>
            </div>

            <div className={ objectProp.isMobile ?
                "w-[100%] h-auto relative"
                : objectProp.isTablet ? "w-[50%] h-[100%] relative"
                : objectProp.isTabletBig ? "w-[50%] h-[100%] relative"
                : "w-[40%] h-[100%] relative"
            }>
                <img src={Asset18} className={ objectProp.isMobile ?
                    "w-[100%] h-auto bg-cover absolute bottom-0"
                    : objectProp.isTablet ? "w-[100%] h-full"
                    : "w-[100%] h-[450px]"
                } />
                <div className={ objectProp.isMobile ?
                    "absolute bottom-[20px] w-[60%] text-white ml-[65px]"
                    : objectProp.isTablet ? "absolute bottom-[20px] w-[60%] text-white ml-[65px]"
                    : "absolute bottom-[20px] w-[60%] text-white ml-[65px]"
                }>
                    <p className={ objectProp.isMobile ?
                        "text-[20px] font-bold ml-[-40px]"
                        : objectProp.isTablet ? "text-[30px] font-bold"
                        : "text-[30px] font-bold"
                    }>LUẬT</p>
                    <p className={objectProp.isMobile ?
                        "w-[130%] h-auto ml-[-40px] mb-[10px] text-[13px]"
                        : objectProp.isTablet ? ""
                        : ""
                    }>Cơ hội nghề nghiệp rộng mở, với chuyên ngành đào tạo đáp ứng thị trường hội nhập đa quốc gia</p>
                </div>
            </div>
        </div>
    </div>
  )
}
