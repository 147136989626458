import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Asset9 from '../img/Asset9.png'
import Asset31 from '../img/Asset31.png'
import Asset32 from '../img/Asset32.png'
import Asset33 from '../img/Asset33.png'
import Asset34 from '../img/Asset34.png'
import Asset35 from '../img/Asset35.png'
import axios from 'axios'
import logo from '../img/logo.png'

export default function Footer({objectProp}) {
    const [HoTen, setHoTen] = useState('')
    const [DienThoai, setDienThoai] = useState('')
    const [Email, setEmail] = useState('')
    const [Nganh, setNganh] = useState('')
    
    const handleSubmit = async () =>{
        try {
            if(HoTen==='') return alert("Chưa nhập họ và tên.")
            if(DienThoai==='') return alert("Chưa nhập số điện thoại.")
            if(DienThoai.length > 10 || DienThoai.length < 10) return alert("Nhập sai số điện thoại.")
            if(Email==='') return alert("Chưa nhập email.")
            if(Nganh==='0' || Nganh==='') return alert("Chưa chọn ngành cần tư vấn.")
            
            const request =  await axios.post('https://adminmember.myu.vn/api/dangky_euni', {FullName: HoTen, PhoneNumber: DienThoai, Email: Email, Major: Nganh});
            if(request) return alert("Đăng ký thành công!")
            
            setHoTen('')
            setDienThoai('')
            setEmail('')
            setNganh('')

        } catch (error) {
            alert('Error:', error)
        }
    }

    const ulStyle = {
        listStyleType: 'disc', // Kiểu dấu chấm (bullet points)
        paddingLeft: '20px' // Độ thụt lề để hiển thị dấu chấm
    };

  return (
    <div className={ objectProp.isMobile ?
        "w-full h-auto mt-[920px] relative"
        : objectProp.isTablet ? "w-full h-auto mt-[650px] relative"
        : objectProp.isTabletBig ? "w-full h-auto mt-[20px] relative"
        : "w-full h-auto mt-[20px] m-auto relative"
    } id="footer">
        <img src={Asset9} className={ !objectProp.isMobile ? 
            "w-full h-[640px]"
            : "w-full h-[100%] bg-repeat hidden"
        } />
        <img src={Asset31} className={ objectProp.isMobile ?
            "w-[70px] absolute top-[30px] left-[50%] translate-x-[-50%] bg-[#0033CC] p-[15px] rounded-[50%]"
            : objectProp.isTablet ? "w-[70px] absolute top-[22px] left-[50%] translate-x-[-50%] bg-[#0033CC] p-[15px] rounded-[50%]"
            : "w-[70px] absolute top-[45px] left-[50%] translate-x-[-50%] bg-[#0033CC] p-[15px] rounded-[50%]"
        } />

        <div className={`${objectProp.isMobile?'hidden':''} w-full h-auto absolute top-[150px]`}>
            {/* <div className={ objectProp.isMobile ?
                "w-[100%] h-auto bg-[#CED3F3] px-[50px] py-[30px] rounded-[20px] mx-[20px] mt-[-80px]"
                : objectProp.isTablet ? "w-[100%] h-auto bg-[#CED3F3] px-[50px] py-[30px] rounded-[20px] mx-[20px] mt-[-60px]"
                : "w-[40%] h-auto bg-[#CED3F3] px-[50px] py-[15px] rounded-[20px]"
            }>
                <form>
                    <p className={ !objectProp.isMobile ?
                        "text-[25px] text-[#0046CD] font-bold text-center"
                        : "text-[20px] text-[#0046CD] font-bold text-center"
                    }>THÔNG TIN ĐĂNG KÝ</p>
                    
                    <input type="text" placeholder="Họ và tên" value={HoTen} onChange={(e)=>setHoTen(e.target.value)}
                    className="text-[#9CA3AF] box-shadow-3 rounded-[20px] w-full h-[35px] px-[25px] mt-[15px] outline-none" />

                    <input type="text" placeholder="Số điện thoại" value={DienThoai} onChange={(e)=>setDienThoai(e.target.value)}
                    className="text-[#9CA3AF] box-shadow-3 rounded-[20px] w-full h-[35px] px-[25px] mt-[15px] outline-none" />

                    <input type="email" placeholder="Email" value={Email} onChange={(e)=>setEmail(e.target.value)}
                    className="text-[#9CA3AF] box-shadow-3 rounded-[20px] w-full h-[35px] px-[25px] mt-[15px] outline-none" />

                    <select value={Nganh} onChange={(e)=>setNganh(e.target.value)} className="text-[14px] text-[#9CA3AF] box-shadow-3 rounded-[20px] w-full h-[35px] px-[25px] mt-[15px] outline-none">
                        <option value="0">Ngành học cần tư vấn</option>
                        <option value="Ngôn Ngữ Anh">Ngôn Ngữ Anh</option>
                        <option value="Luật">Luật</option>
                    </select>
                </form>

                <button className="bg-[#FF9B00] text-white text-[23px] font-bold shadow-xl hover:shadow-none
                px-[40px] py-[2px] rounded-[30px] ml-[50%] translate-x-[-50%] mt-[20px] 
                transition-all duration-500 linear" onClick={handleSubmit}>GỬI</button>
            </div> */}
            <div className='flex justify-center'>
                <div className={ objectProp.isMobile ?
                    "w-[100%] h-auto py-[30px] mx-[20px]"
                    : objectProp.isTablet ? "w-[100%] h-auto py-[30px] mx-[20px]"
                    : "w-[40%] h-auto py-[0px]"
                }>
                    <img src={logo} alt='Logo' className='w-[90px] m-auto'/>
                    <p className={ objectProp.isMobile ?
                        "text-[23px] text-[#001666] font-bold"
                        : objectProp.isTablet ? "text-[23px] text-[#001666] font-bold text-center"
                        : "text-[25px] text-white font-bold text-center"
                    }>TRƯỜNG ĐẠI HỌC VĂN HIẾN</p>
                    <p className={ objectProp.isMobile ?
                        "text-[23px] text-[#001666] font-bold"
                        : objectProp.isTablet ? "text-[20px] text-[#001666] font-bold text-center"
                        : "text-[20px] text-white font-bold text-center"
                    }><i>Thành Nhân trước thành Danh</i></p>
                </div>

                <div className={ objectProp.isMobile ?
                    "w-[100%] h-auto py-[30px] mx-[20px]"
                    : objectProp.isTablet ? "w-[100%] h-auto py-[30px] mx-[20px]"
                    : "w-[40%] h-auto py-[0px]"
                }>
                    <div className={ objectProp.isMobile ?
                        "text-[20px] text-[#001666] mt-[20px]"
                        : objectProp.isTablet ? "text-[20px] text-[#001666] mt-[20px] text-center"
                        : "text-[20px] text-white mt-[20px]"
                    }>
                        <p><b>Trụ sở chính:</b></p>
                        <ul style={{listStyleType:'disc'}}>
                            <li>HungHau House: 613 Âu Cơ, Phường Phú Trung, Quận Tân Phú, TP.HCM</li>
                        </ul>
                        <br/>
                        <p><b>Các cơ sở đào tạo:</b></p>
                        <ul style={{listStyleType:'disc'}}>
                            <li>Harmony Campus: 624 Âu Cơ, Phường 10, Quận Tân Bình, TP. HCM</li>
                            <li>HungHau Campus: Đại lộ Nguyễn Văn Linh, Khu đô thị Nam Thành phố.</li>
                            <li>myU Campus: 665 - 667 - 669 Điện Biên Phủ, Phường 1, Quận 3, TP. HCM</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={ objectProp.isMobile ?
                "flex mt-[30px]"
                : objectProp.isTablet ? "flex justify-center mt-[30px]"
                : "flex mt-[30px] justify-center"
            }>
                <a href="https://www.facebook.com/profile.php?id=61553014247056" target="_blank">
                    <img src ={Asset32} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
                <a href="https://www.linkedin.com/school/van-hien-university/" target="_blank">
                    <img src={Asset33} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
                <a href="https://www.youtube.com/@VhuEduVn-daihocvanhien" target="_blank">
                    <img src={Asset34} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
                <a href="https://www.tiktok.com/@vhu_vanhienuniversity" target="_blank">
                    <img src={Asset35} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
            </div>
        </div>
        
        <div className={`${objectProp.isMobile?'text-center pt-[120px]':'hidden'}`}>
            <p className={ objectProp.isMobile ?
                "text-[23px] text-[#001666] font-bold"
                : objectProp.isTablet ? "text-[23px] text-[#001666] font-bold text-center"
                : "text-[25px] text-white font-bold"
            }>TRƯỜNG ĐẠI HỌC VĂN HIẾN</p>

            <p className={ objectProp.isMobile ?
                "text-[20px] text-[#001666] mt-[20px]"
                : objectProp.isTablet ? "text-[20px] text-[#001666] mt-[20px] text-center"
                : "text-[22px] text-white mt-[20px]"
            }>
                <p><b>Trụ sở chính:</b></p>
                <ul style={{listStyleType:'disc'}}>
                    <li>HungHau House: 613 Âu Cơ, Phường Phú Trung, Quận Tân Phú, TP.HCM</li>
                </ul>
                <br/>
                <p><b>Các cơ sở đào tạo:</b></p>
                <ul style={{listStyleType:'disc'}}>
                    <li>Harmony Campus: 624 Âu Cơ, Phường 10, Quận Tân Bình, TP. HCM</li>
                    <li>HungHau Campus: Đại lộ Nguyễn Văn Linh, Khu đô thị Nam Thành phố.</li>
                    <li>myU Campus: 665 - 667 - 669 Điện Biên Phủ, Phường 1, Quận 3, TP. HCM</li>
                </ul>
            </p>
            <div className={ objectProp.isMobile ?
                "flex mt-[30px] justify-center"
                : objectProp.isTablet ? "flex justify-center mt-[30px]"
                : "flex mt-[20px]"
            }>
                <a href="https://www.facebook.com/profile.php?id=61553014247056" target="_blank">
                    <img src ={Asset32} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
                <a href="https://www.linkedin.com/school/van-hien-university/" target="_blank">
                    <img src={Asset33} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
                <a href="https://www.youtube.com/@VhuEduVn-daihocvanhien" target="_blank">
                    <img src={Asset34} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
                <a href="https://www.tiktok.com/@vhu_vanhienuniversity" target="_blank">
                    <img src={Asset35} className="w-[54px] h-[55px] ml-[20px]" />
                </a>
            </div>
            <div className='text-center text-[#003177] text-[14px] py-[8px] mt-[20px]'>Copyright 2023 © Đại học Văn Hiến</div>
        </div>

        <div className={ objectProp.isMobile ?
            "hidden text-center text-[#003177] text-[14px] py-[8px] mt-[530px]"
            : objectProp.isTablet ? "text-center text-[#003177] text-[18px] py-[8px] mt-[470px] "
            : objectProp.isTabletBig ? "text-center text-[#003177] text-[18px] py-[8px] mt-[200px] "
            : "text-center text-[#003177] text-[20px] py-[5px]"
        }>Copyright 2023 © Đại học Văn Hiến</div>
    </div>
  )
}
