import React from 'react'
import Asset30 from '../img/Asset30.png'
import { GoDotFill } from "react-icons/go";

export default function LearnEverytime({objectProp}) {
  return (
    // học mọi lúc - mọi nơi
    <div className={ objectProp.isMobile ?
        "w-full h-[400px] flex mt-[150px] flex-wrap"
        : objectProp.isTablet ? "w-full h-[400px] flex mt-[230px] flex-wrap"
        : "max-w-[1280px] w-full h-[400px] m-auto flex"
    }>
        <div className={ !objectProp.isMobile ?
            "w-[50%] h-full relative"
            : "w-[100%] h-full relative"
        }>
            <div className={ objectProp.isMobile ?
                "w-[77%] h-[300px] bg-[#FF9300] absolute top-[50px] left-[50%] translate-x-[-50%] border-radius-tl-br box-shadow-1"
                : objectProp.isTablet ? "w-[85%] h-[300px] bg-[#FF9300] absolute top-[50px] left-[50%] translate-x-[-50%] border-radius-tl-br box-shadow-1"
                : "w-[77%] h-[295px] bg-[#FF9300] absolute bottom-0 right-0 border-radius-tl-br box-shadow-1"
            }>
                <img src={Asset30} className={ objectProp.isMobile ?
                    "absolute bottom-[-20px] left-[-5px]"
                    : objectProp.isTablet ? "absolute bottom-[-13px] left-[-5px]"
                    : "absolute bottom-[-33px] left-[-8px]"
                }/>
                <div className=" w-[30px] h-[50%] ml-auto border-l-[3px] border-white"></div>
            </div> 
        </div>

        <div className={ !objectProp.isMobile ?
            "w-[50%] h-full pl-[60px]"
            : "max-w-[2000px] w-[100%] h-full px-[20px]"
        }>
            <p className={ objectProp.isMobile ?
                "text-[#0046CD] text-[30px] font-bold mt-[-10px]"
                : objectProp.isTablet ? "text-[#0046CD] text-[30px] font-bold mt-[-10px]"
                : "text-[#0046CD] text-[40px] font-bold mt-[50px]"
            }>HỌC ĐẠI HỌC</p>

            <p className={ objectProp.isMobile ?
                "text-[#FF9300] text-[30px] font-bold"
                : objectProp.isTablet ? "text-[#FF9300] text-[30px] font-bold"
                : "text-[#FF9300] text-[40px] font-bold"
            }>MỌI LÚC - MỌI NƠI</p>

            <div className="flex items-center mt-[30px] w-full h-auto">
                {/* <div className="w-[15px] h-[15px] rounded-[50%] bg-[#FF9300] mr-[15px]"></div> */}
                <GoDotFill color="#FF9300" size={"25px"} />
                <p className="text-[#4E4F4F] text-[22px] ml-[20px]">Tiết kiệm chi phí và tối ưu thời gian</p>
            </div>
            <div className="flex items-center mt-[20px] w-full h-auto">
                <GoDotFill color="#FF9300" size={ !objectProp.isMobile ? "25px" : "30px"} />
                <p className="text-[#4E4F4F] text-[22px] ml-[20px]">Bằng có giá trị tương đương đào tạo chính quy</p>
            </div>
            <div className="flex items-center mt-[20px] w-full h-auto">
                <GoDotFill color="#FF9300" size={"25px"} />
                <p className="text-[#4E4F4F] text-[22px] ml-[20px]">Đầu vào và ra của thạc sĩ và tiến sĩ</p>
            </div>
        </div>
    </div>
  )
}
