import React from 'react'
import Bg2 from '../img/bg2.png'
import Asset36 from '../img/Asset36.png'
import Asset37 from '../img/Asset37.png'

export default function FormOfLearning({objectProp}) {
  return (
    // hình thức học
    <div className={ objectProp.isMobile ?
        "w-full h-[400px] relative mt-[140px]"
        : objectProp.isTablet ? "w-full h-[400px] relative mt-[160px]"
        : "w-full h-[400px] m-auto relative"
    } id="forOfLearning">
        <img src={Bg2} className={
          objectProp.isMobile ?
          "w-full h-[550px]"
          : objectProp.isTablet ? "w-full h-[600px]"
          : objectProp.isTabletBig ? "w-full h-[400px]"
          : "w-full h-[400px]"
        } />

        <p className={ !objectProp.isMobile ?
            "absolute top-[30px] left-[50%] translate-x-[-50%] text-[40px] text-white font-bold]"
            : "w-full absolute top-[30px] left-[50%] translate-x-[-50%] text-[25px] text-center text-white font-bold]"
        }>HÌNH THỨC HỌC</p>

        <div className="max-w-[2000px] w-full h-[60%] absolute top-[120px] left-[50%] translate-x-[-50%] flex flex-wrap justify-around">
            <div className={ !objectProp.isMobile ?
                "w-[430px] h-full pl-[50px]"
                : "w-[100%] h-full mx-[20px] mt-[-30px]"
            }>
                <img src={Asset37} className="w-full" />
            </div>
            <div className={ !objectProp.isMobile ?
                "w-[360px] h-full"
                : "w-[100%] h-full mx-[20px] mt-[-30px]"
            }>
                <img src={Asset36} />
            </div>
        </div>
    </div>
  )
}
