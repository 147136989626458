import React from 'react'
import bgIntrolRight from '../img/Asset3.png'

export default function Intro({objectProp}) {
  return (
    // giới thiệu
    <div className={ objectProp.isTablet ?
        " w-full h-[500px] m-auto flex"
        : " w-full h-[680px] m-auto flex"
    } id="main">
        <div className={ !objectProp.isMobile ?
            "w-[45%] h-[100%] relative"
            : "w-[60%]"
        }>
            <div className="bg-[#EAF7FF] max-w-[230px] h-[270px] z-10"></div>
        </div>
        <div className={ !objectProp.isMobile ?
            "w-[55%] h-[100%]"
            : "w-[40%]" 
            }>
            <img src={bgIntrolRight} className="w-[100%] h-[100%]" />
        </div>
    </div>
  )
}
