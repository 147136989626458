import React from 'react'
import Asset10 from '../img/Asset10.png'
import Submission from './Submission'
import { GoDotFill } from "react-icons/go";

export default function AdmissionMethod({objectProp}) {
  return (
    // phương thức xét tuyển
    <div className={ !objectProp.isMobile ?
        "w-full h-auto m-auto relative"
        : "w-full h-auto relative mt-[320px]"
    }>
        <img src={Asset10} className="w-full h-[900px]" id="method" />
        <div className="max-w-[2000px] w-full h-[350px] absolute top-0 left-[50%] translate-x-[-50%] flex flex-wrap">
            <div className={ objectProp.isMobile ?
                "w-[100%] h-full pt-[50px]"
                : objectProp.isTablet ? "w-[100%] h-full pt-[80px]"
                : "w-[50%] h-full pt-[50px]"
            }>
                <p className={ objectProp.isMobile ?
                    "text-[25px] text-[#0046CD] font-bold mx-[20px]"
                    : objectProp.isTabletBig ? "text-[35px] text-[#0046CD] font-bold ml-[80px]"
                    : "text-[35px] text-[#0046CD] font-bold text-center"
                }>HÌNH THỨC TUYỂN SINH: <br/> XÉT TUYỂN</p>

                <div className={ !objectProp.isMobile ?
                    "w-full h-auto pl-[100px] pr-[20px] mt-[20px]"
                    : "w-full h-auto px-[20px] mt-[20px]"
                }>
                    {/* <p className="text-[22px] text-[#4E4F4F]">Điền phiếu đăng ký xét tuyển tại:</p> */}
                    <p className="text-[22px] text-[#4E4F4F] text-justify"><b>- Thí sinh tốt nghiệp THPT:</b> có tổng điểm trung bình chung cả năm lớp 12 đạt từ 5.0 điểm.</p>
                    <p className="text-[22px] text-[#4E4F4F] text-justify"><b>- Thí sinh tốt nghiệp trung cấp, cao đẳng: </b> có kết quả học tập toàn khóa đạt từ 5.0 trở lên (tính theo thang điểm 10), đạt từ 2,0 trở lên (tính theo thang điểm 4).</p>
                    {/* <div className="flex items-center">
                        <div className="w-[130px] h-[4px] bg-[#FF9300] mr-[20px] mt-[5px]"></div>
                        <p className="text-[#4E4F4F] text-[22px] font-bold"><a href="www.dangky.vhu.edu.vn">www.dangky.vhu.edu.vn</a></p>
                    </div> */}
                    {/* <p className="text-[22px] text-[#4E4F4F] mt-[20px]">Điền phiếu đăng ký xét tuyển tại:</p>
                    <div className="flex items-center">
                        <div className="w-[130px] h-[4px] bg-[#FF9300] mr-[20px] mt-[5px]"></div>
                        <p className="text-[#4E4F4F] text-[22px] font-bold">30.000 đồng/hồ sơ</p>
                    </div> */}
                </div>
            </div>

            <div className={ objectProp.isMobile ?
                "w-[100%] h-auto mx-[20px] mt-[-50px]"
                : objectProp.isTablet ? "w-[100%] h-auto mx-[20px] mt-[-30px]"
                : "w-[50%] h-full pt-[90px] pr-[80px]"
            }>
                <div className={ objectProp.isMobile ?
                    "w-full h-full bg-[#0046CD] rounded-[30px] box-shadow-2 p-[20px] mt-[120px]"
                    : objectProp.isTabletBig ? "w-full h-full bg-[#0046CD] rounded-[30px] box-shadow-2 p-[20px] mt-[50px] ml-[50px]"
                    : "w-[90%] h-full bg-[#0046CD] rounded-[30px] box-shadow-2 p-[20px]"
                }>
                    <p className='text-white text-[20px] text-justify'>
                        - Thí sinh nộp hồ sơ đăng ký xét tuyển trực tiếp tại Trường, gửi qua đường Bưu điện hoặc đăng ký online tại địa chỉ: 
                        <a href='https://dangky.vhu.edu.vn'> <u>https://dangky.vhu.edu.vn</u></a> 
                    </p>

                    <a href='https://dangky.vhu.edu.vn'>
                        <button className="bg-white box-shadow rounded-[20px] 
                        px-[30px] py-[7px] text-[#6D6D6D] hover:shadow-none hover:border-x-[1px] hover:border-y-[1px]
                        transition-all duration-500 linear font-bold mt-[20px] ml-[50%] translate-x-[-50%]">Đăng ký ngay</button>
                    </a>

                    {/* <div className="flex items-center">
                        <GoDotFill color="#FFBE66" size={!objectProp.isMobile ? "20px" : "35px"} />
                        <p className="text-white text-[20px] ml-[20px]">Bản sao bằng tốt nghiệp THPT hoặc giấy tốt nghiệp tạm thời.</p>
                    </div>
                    <div className="flex items-center mt-[10px]">
                        <GoDotFill color="#FFBE66" size={!objectProp.isMobile ? "20px" : "30px"} />
                        <p className="text-white text-[20px] ml-[20px]">Bản sao xác nhận bằng cấp, bảng điểm khác (nếu có);</p>
                    </div>
                    <div className="flex items-center mt-[10px]">
                        <GoDotFill color="#FFBE66" size={"20px"} />
                        <p className="text-white text-[20px] ml-[20px]">CCCD công chứng và 02 ảnh thẻ 3x4</p>
                    </div> */}
                </div>
            </div>
        </div>
        <Submission objectProp={objectProp} />
    </div>
  )
}
