import React, {useEffect, useState} from 'react'
import Asset2 from '../img/Asset2.png'
import bgIntro from '../img/bg-intro.png'
import Asset40 from '../img/Asset40.png'
import Asset41 from '../img/Asset41.png'
import { Link } from 'react-scroll';

export default function IntroFloating({objectProp}) {
  return (
    // giới thiệu
    <div className="max-w-[1900px] w-[100%] h-[350px] absolute top-[190px] left-[50%] translate-x-[-50%]">
        <div className={!objectProp.isMobile ? "ml-[80px] text-[#001666]" : "absolute z-[12] top-[200px] left-[20px] text-[#001666]"}>
            <p className={ objectProp.isMobile ?
                "text-[30px] pb-[10px]"
                : objectProp.isTablet ? "text-[30px] absolute z-[10]"
                : objectProp.isTabletBig ? "text-[35px] absolute z-[10]"
                : "text-[40px]"
            }>CHƯƠNG TRÌNH</p>  

            <p className={ objectProp.isMobile ?
                "text-[50px] font-bold mt-[-20px]"
                : objectProp.isTablet ? "text-[40px] font-bold absolute z-[10] top-[40px]"
                : objectProp.isTabletBig ? "text-[50px] font-bold absolute z-[10] top-[40px]"
                : "text-[70px] font-bold mt-[-20px]"
            }>ĐẠI HỌC<br/>TỪ XA</p>
        </div>
        <div className="mt-[-15px] relative">
            <a activeClass="active" href="https://dangky.vhu.edu.vn/" spy={true} smooth={true} offset={0} duration={500}>
                {
                    objectProp.isMobile ?            
                    <button className="bg-white absolute top-[460px] left-[20px] z-[13]bg-white
                    box-shadow rounded-[20px] px-[30px] py-[7px] text-[#6D6D6D] hover:shadow-none hover:border-x-[1px] hover:border-y-[1px]
                    transition-all duration-500 linear font-bold">
                    Đăng ký ngay</button>
                    
                    : objectProp.isTablet ? 
                    <button className="bg-white absolute z-[11] top-[200px] left-[80px] box-shadow rounded-[20px] 
                    px-[30px] py-[7px] text-[#6D6D6D] hover:shadow-none hover:border-x-[1px] hover:border-y-[1px]
                    transition-all duration-500 linear font-bold">Đăng ký ngay</button>

                    : objectProp.isTabletBig ? 
                    <button className="bg-white absolute z-[11] top-[250px] left-[80px] box-shadow rounded-[20px] 
                    px-[30px] py-[7px] text-[#6D6D6D] hover:shadow-none hover:border-x-[1px] hover:border-y-[1px]
                    transition-all duration-500 linearfont-bold">Đăng ký ngay</button>

                    : <button className="bg-white absolute z-[11] top-[90px] left-[80px] box-shadow rounded-[20px] 
                    px-[30px] py-[7px] text-[#6D6D6D] hover:shadow-none hover:border-x-[1px] hover:border-y-[1px]
                    transition-all duration-500 linear font-bold">Đăng ký ngay</button>
                }
            </a>
            <img src={Asset2} className={ objectProp.isMobile
                ? "hidden"
                : objectProp.isTablet ? "hidden"
                : objectProp.isTabletBig ? "hidden"
                : "w-[250px] h-auto mt-[-5px] absolute bottom-[-150px] right-[55%] z-10"
                }/>
            
            <img src={bgIntro} className={ objectProp.isMobile 
                ? "w-[80%] h-auto absolute z-[0] top-[-80px] left-[50%] translate-x-[-50%]"
                : objectProp.isTablet ? "w-[50%] h-auto absolute z-[0] top-[-70px] right-[20px]"
                : objectProp.isTabletBig ? "w-[50%] h-auto absolute z-[0] top-[-40px] right-[20px]"
                : "w-[530px] h-auto ml-[45%] translate-x-[-40px] absolute bottom-[-150px] z-[0]"
                }/>
            
            <div className={ objectProp.isMobile ?
                "pt-[60px] absolute w-[100%] mt-[300px]"
                : objectProp.isTablet ? "pt-[60px] absolute w-[100%] top-[140px]"
                : objectProp.isTabletBig ? "pt-[60px] absolute w-[100%] top-[150px]"
                : "pt-[60px] absolute w-[100%] bg-"
            }>
                <Link activeClass="active" to="footer" spy={true} smooth={true} offset={0} duration={500}>
                    <img src={Asset40} className={ !objectProp.isMobile ?
                        "w-[85px] h-auto ml-[auto] bg-white px-[15px] py-[13px] rounded-[50%] border-[#0018C8] border-x-[18px] border-y-[18px] mr-[60px] cursor-pointer" 
                        : "w-[85px] h-auto ml-[auto] bg-white px-[15px] py-[13px] rounded-[50%] border-[#0018C8] border-x-[18px] border-y-[18px] mr-[20px] cursor-pointer" 
                        }/>
                </Link>
                <div className={ !objectProp.isMobile && !objectProp.isTablet && !objectProp.isTabletBig ?
                    "w-[100px] h-[100px] ml-auto border-white border-l-[3px] mt-[10px]"
                    : "hidden"}></div>
            </div>
        </div>
    </div>
  )
}
