import { useState, useEffect, useContext } from 'react';
import React from 'react'
import Logo from '../img/Logovhu.png'
import { Link } from 'react-scroll'
import { IoMenu } from "react-icons/io5"

export default function Header({objectProp, scrollPosition}) {

    const [click, setClick] = useState(false)
    const clickMenu = ()=>{
        setClick(!click)
    }

  return (
    <div  className={(scrollPosition.y > 80) || click
        ? "bg-white max-w-[1280px] w-full h-[110px] ml-[50%] translate-x-[-50%] flex justify-between py-[30px] fixed z-[100]"
        :"max-w-[1280px] w-full ml-[50%] translate-x-[-50%] flex justify-between py-[40px] fixed z-[100]"}
        id="header">
            <div className={ !objectProp.isMobile ?
                "w-[50%]"
                : "w-[80%]"
            }>
                <Link activeClass="active" to="main" spy={true} smooth={true} offset={0} duration={500} onClick={()=>setClick(false)}>
                    <img src = {Logo} className={ !objectProp.isMobile ?
                        "w-[65%] h-auto ml-[70px] cursor-pointer"
                        : "w-[220px] h-auto ml-[20px] cursor-pointer"
                    } />
                </Link>
            </div>
            <div className={ !click ?
                "w-[50%] hidden"
                : "block absolute top-[70px] bg-white w-full shadow-xl"
                }>
                <ul className={ objectProp.isTablet ?
                    "mt-[5px] pl-[70px]"
                    : objectProp.isTabletBig ? "mt-[5px] pl-[70px]"
                    : "mt-[5px] px-[20px]"
                }>
                    <Link activeClass="active" to="majors" spy={true} smooth={true} offset={-120} duration={500} onClick={()=>setClick(false)}>
                        <li className= "text-[#001666] font-bold my-[15px]">GIỚI THIỆU</li>
                    </Link>
                    <Link activeClass="active" to="object" spy={true} smooth={true} offset={-120} duration={500} onClick={()=>setClick(false)}>
                        <li className="text-[#001666] font-bold mb-[15px]">ĐỐI TƯỢNG TUYỂN SINH</li>
                    </Link>
                    <Link activeClass="active" to="method" spy={true} smooth={true} offset={-120} duration={500} onClick={()=>setClick(false)}>
                        <li className="text-[#001666] font-bold mb-[15px]">HỒ SƠ XÉT TUYỂN</li>
                    </Link>
                    <Link activeClass="active" to="forOfLearning" spy={true} smooth={true} offset={-120} duration={500} onClick={()=>setClick(false)}>
                        <li className="text-[#001666] font-bold mb-[15px]">ƯU ĐIỂM</li>
                    </Link>
                    <Link activeClass="active" to="footer" spy={true} smooth={true} offset={0} duration={500} onClick={()=>setClick(false)}>
                        <li className="text-[#001666] font-bold my-[15px]">LIÊN HỆ</li>
                    </Link>
                </ul>
            </div>
        {
            objectProp.isMobile || objectProp.isTablet || objectProp.isTabletBig ?
            <IoMenu size={"30px"} color="#001666" className="bg-white rounded-[5px] mr-[20px] border-[1px] border-[#324a9e]"
            onClick={clickMenu} />
            : 
            <div className="w-[50%]">
                <ul className="flex justify-around mt-[5px] px-[20px]">
                    <Link activeClass="active" to="majors" spy={true} smooth={true} offset={-120} duration={500}>
                        <li className={scrollPosition.y > 80
                            ?"check-header-scroll":"check-header"}>GIỚI THIỆU</li>
                    </Link>
                    <Link activeClass="active" to="object" spy={true} smooth={true} offset={-120} duration={500}>
                        <li className={scrollPosition.y > 80 ?"check-header-scroll":"check-header"}>ĐỐI TƯỢNG <br/> TUYỂN SINH</li>
                    </Link>
                    <Link activeClass="active" to="method" spy={true} smooth={true} offset={-120} duration={500}>
                        <li className={scrollPosition.y > 80 ?"check-header-scroll":"check-header"}>HỒ SƠ <br/> XÉT TUYỂN</li>
                    </Link>
                    <Link activeClass="active" to="forOfLearning" spy={true} smooth={true} offset={-120} duration={500}>
                        <li className={scrollPosition.y > 80 ?"check-header-scroll":"check-header"}>ƯU ĐIỂM</li>
                    </Link>
                    <Link activeClass="active" to="footer" spy={true} smooth={true} offset={0} duration={500}>
                        <li className={scrollPosition.y > 80 ?"check-header-scroll":"check-header"}>LIÊN HỆ</li>
                    </Link>
                </ul>
            </div>
        }
    </div>
  )
}
