import { useState, useEffect } from 'react'
import Header from './components/Header'
import Intro from './components/Intro'
import IntroFloating from './components/IntroFloating'
import Majors from './components/Majors'
import Object from './components/Object'
import FormOfLearning from './components/FormOfLearning'
import LearnEverytime from './components/LearnEverytime'
import AdmissionMethod from './components/AdmissionMethod'
import Footer from './components/Footer'

function App() {
    // độ dài , rộng màn hình
    const [windownSize, setWindownSize] = useState({width: undefined, height: undefined,})
    const [isMobile, setIsmobile] = useState(false)
    const [isTablet, setTablet] = useState(false)
    const [isTabletBig, setTabletBig] = useState(false)

    // tọa độ khi cuộn chuột
    const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

    useEffect(()=>{
        const handleSize = () =>{
            setWindownSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleSize)
        handleSize()
        return () => { window.removeEventListener('resize', handleSize)}
    },[])

    useEffect(()=>{
        windownSize.width <= 500 ? setIsmobile(true) : setIsmobile(false)
        windownSize.width >= 501 && windownSize.width < 800 ? setTablet(true) : setTablet(false)
        windownSize.width >= 800 && windownSize.width <= 1024 ? setTabletBig(true) : setTabletBig(false)
    },[windownSize])

    // tọa độ khi cuộn chuột
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition({ x: window.scrollX, y: window.scrollY });
        }

        window.addEventListener('scroll', handleScroll)
        handleScroll()
        return () => { window.removeEventListener('scroll', handleScroll)}
    },[])

    const objectProp = {
        isMobile: isMobile,
        isTablet: isTablet,
        isTabletBig: isTabletBig
    }

    return (
        <div className="m-auto">
            <div className={ scrollPosition.y > 80 ?
                "w-full bg-white fixed h-[112px] z-[100] shadow-xl"
                : "w-full fixed h-[110px] z-[10]"
            }>
            </div>
            <Header objectProp={objectProp} scrollPosition={scrollPosition} />
            <Intro objectProp={objectProp} />
            <IntroFloating objectProp={objectProp} />
            <Majors objectProp={objectProp} />
            <Object objectProp={objectProp} />
            <FormOfLearning objectProp={objectProp} />
            <LearnEverytime objectProp={objectProp} />
            <AdmissionMethod objectProp={objectProp} />
            <Footer objectProp={objectProp} />
        </div>
    );
}

export default App;
